const PATHS = {
  home: '/home',
  login: '/login',
  curriculum: '/home/curriculum', // 不太合理 重定向到 lesson
  playback: '/home/lesson/playback',
  leave: '/home/leave',
  closeAccount: '/home/close-account',
  profile: '/home/profile',
  period: '/home/period',
  ai: '/home/ai',
  aiList: '/home/ai/list',
  aiLesson: '/home/ai/lesson',
  remark: '/remark',
  remarkResult: '/remark/result',
  share: '/home/share',
  reserve: '/reserve',
  reserveChinese: '/reserve/chinese',
  lesson: '/home/lesson',
  lessonTask: '/home/lesson/task',
  mobileHome: '/m/home',
  mobileLogin: '/m/login',
  mobileRegister: '/m/register', // 注册
  mobileRetrievePassword: '/m/retrieve-password', // 忘记密码
  classTutorial: '/m/home/class-tutorial', // 如何上课
  mobileReserve: '/m/reserve', // 数学约课
  mobileReserveChinese: '/m/reserve/chinese', // 中文约课
  trainOrder: '/home/train/order',
  appointmentEnglish: '/home/appointment/english',
  homeChinese: '/home/chinese',
  homeMath: '/home/math',
  trainAdjust: '/home/train/adjust',
  trainCache: '/home/train/cache',
  trainFinished: '/home/train/finished',
  periodDetail: '/home/period/detail',
  mobileTrainOrder: '/m/home/train/order',
  mobileTrainFinished: '/m/home/train/finished',
  orderCenter: '/home/orderCenter',
  orderCenterDetail: '/home/orderCenter/detail',
  subscription: '/home/subscription',
  vouchers: '/home/vouchers',
  appointmentResult: '/home/appointment/result',
  mobileAppointmentResult: '/m/home/appointment/result',
  cognia: '/home/cognia-certificate',
  mobileLearningProgress: '/m/learningProgress/center',
  learningProgress: '/learningProgress/center',
  h5LearningProgress: 'h5/learningProgress/center',
  learnCenter: '/learningProgress/center',
  // MobileUnitDetail: '/m/learningProgress/unit',
  subscriptionDetails: '/home/subscriptionDetails',
};

export default PATHS;
