/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { CSSProperties, FC } from 'react';
import { CSSTransition } from 'react-transition-group';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import styles from './style.module.scss';
import { useUpdateEffect } from 'react-use';

interface PopupProps {
  //   显示隐藏
  visible?: boolean;
  //   关闭时触发
  onClose?: () => void;
  //   点击背景蒙层时触发
  onMaskClick?: () => void;
  title?: string;
  titleClassName?: string;
  showCloseIcon?: boolean;
  popupStyle?: CSSProperties;
  /**
   * 从哪个方向弹出
   * @default bottom
   */
  from?: 'top' | 'bottom';
  /**
   * 弹窗的classname
   */
  popupClassName?: string;
  /**
   * 遮罩层的 classname
   */
  maskClassName?: string;
}

const Popup: FC<PopupProps> = ({
  visible = false,
  onClose,
  onMaskClick,
  title,
  titleClassName,
  children,
  showCloseIcon = true,
  popupStyle,
  from = 'bottom',
  popupClassName,
  maskClassName,
}) => {
  const panelRef = React.useRef<HTMLDivElement>(null);

  useUpdateEffect(() => {
    setTimeout(() => {
      const drawer = panelRef.current;
      // 获取原窗口的高度
      const originalHeight = document.documentElement.clientHeight || document.body.clientHeight;
      const resizeCallback = () => {
        // 键盘弹起与隐藏都会引起窗口的高度发生变化
        const resizeHeight = document.documentElement.clientHeight || document.body.clientHeight;
        if (resizeHeight - 0 < originalHeight - 0 && drawer) {
          // 当软键盘弹起，在此处操作
          drawer.classList.add(styles.topDistance);
        } else if (drawer) {
          // 当软键盘收起，在此处操作
          drawer.classList.remove(styles.topDistance);
        }
      };
      if (visible) {
        window.addEventListener('resize', resizeCallback);
      } else {
        window.removeEventListener('resize', resizeCallback);
      }
    }, 300);
  }, [visible]);

  const handleMaskClick = () => {
    console.log('????');

    if (onClose) {
      onClose();
    }
    if (onMaskClick) {
      onMaskClick();
    }
  };

  const handleIconClick = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <>
      {globalThis.document
        ? ReactDOM.createPortal(
            <>
              <CSSTransition
                unmountOnExit
                timeout={300}
                in={visible}
                classNames={{
                  enter: styles.fade,
                  enterActive: styles.fadeActive,
                  exit: styles.fadeOut,
                  exitActive: styles.fadeOutActive,
                }}
              >
                <div className={classNames(styles.mask, maskClassName)} onClick={handleMaskClick} />
              </CSSTransition>
              <CSSTransition
                unmountOnExit
                timeout={300}
                in={visible}
                classNames={{
                  enter: styles[`slide-${from}-in`],
                  enterActive: styles[`slide-${from}-in-active`],
                  exitActive: styles[`slide-${from}-out-active`],
                }}
              >
                <div
                  ref={panelRef}
                  style={popupStyle}
                  className={classNames(styles.panel, styles[`panel-${from}`], popupClassName)}
                >
                  {title && (
                    <div className={classNames(styles.title, titleClassName)}>
                      <span>{title}</span>
                      {showCloseIcon && (
                        <img
                          onClick={handleIconClick}
                          className={styles.closeImg}
                          src="/imgs/popup_icon_close.svg"
                          alt=""
                        />
                      )}
                    </div>
                  )}
                  {children}
                </div>
              </CSSTransition>
            </>,
            document.body,
          )
        : null}
    </>
  );
};

export default Popup;
